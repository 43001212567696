.login-form {
  padding: 5em;
  -webkit-box-shadow: 0 5px 25px 0 rgba(80, 80, 80, 0.25);
  box-shadow: 0 5px 25px 0 rgba(80, 80, 80, 0.25);
  border-radius: 20px;
}

.form-user-input {
  width: 15em;
  height: 3em;
  border-radius: 0.5em;
}

.form-button {
  width: 15em;
  height: 3em;
  border-radius: 0.5em;
}

.loader {
  margin-top: 10em;
  transition: 1s ease-in-out;
}

.admin-login-text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
  color: red;
}

.admin-login-arrow {
  width: 10%;
  color: blue;
}

.admin-login-description {
  color: grey;
}

.form-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 1s ease-in-out;
  overflow-x: hidden;
}

.error-message {
  color: red;
}

.admin-login-here-block {
  position: absolute;
  bottom: 0;
  margin-left: 20%;
}
@media only screen and (max-width: 768px) {
  .admin-login-here-block {
    margin-left: 5%;
  }
}
