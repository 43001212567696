.number {
  text-align: center;
}

.sticky {
  position: sticky !important;
  top: 0;
  left: 0;
  z-index: 99;
  background: whitesmoke;
}

.view-more-table-data {
  white-space: pre-line !important;
  word-wrap: break-word;
  font-size: 0.75em;
}

.view-more-table-header {
  white-space: pre-line !important;
  word-wrap: normal;
  font-size: 0.8em;
}

.po-table-header {
  white-space: pre-line !important;
  word-wrap: break-word;
}

.table-data { 
  box-shadow: 0 5px 10px lightgrey;
  padding: 0;
  background-color: white;
}
@media only screen and (max-width: 768px) {
  .table-data {
    width: 95%;
  }
}

.select-label {
  font-weight: 500;
}

.filter-search-by-input, .animate-filter-search-by-input-exit-active, .animate-filter-search-by-input-exit, .animate-filter-search-by-input-enter-done, .animate-filter-search-by-input-enter-active {
  border-style: solid;
  border-radius: 5px;
}

.animate-filter-search-by-input-enter {
  width: 0%;
}

.animate-filter-search-by-input-enter-active {
  width: 50%;
  transition: width 400ms;
}

.animate-filter-search-by-input-enter-done {
  width: 50%;
}

.animate-filter-search-by-input-exit {
  width: 50%;
}

.animate-filter-search-by-input-exit-active {
  width: 0%;
  transition: width 400ms;
}

.filter-status-dropdown {
  text-align: right;
}

.select-button {
  color: #444;
  background-color: #fff;
  line-height: 5%;
  font-weight: 500;
  border: 1px solid #aaa;
  border-radius: 0.35em;
  padding: 0.1em 0.1em 0.1em 0.1em;
}
.select-button:hover {
  border-color: #888;
}
.select-button:focus {
  border-color: #aaa;
  color: #222;
  outline: none;
  box-shadow: 0 0 0 1.5px #b8bbd7;
}
.select-button option {
  font-weight: normal;
}

.table-style {
  height:calc(100vh - 25vh);
  /* height: 82vh; */
}
@media only screen and (max-width: 768px) {
  .table-style {
    height: 90vh;
  }
}

.status-button {
  font-size: 0.85em;
  padding: 1%;
  margin-left: 2em;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .overlay {
    width: 185%;
  }
}

.user-input-text {
  display: inline;
}
@media only screen and (max-width: 768px) {
  .user-input-text {
    font-size: 75%;
  }
}

.col-input {
  white-space: nowrap;
  padding: 0%;
}

.col-buttons {
  white-space: nowrap;
  padding: 0%;
  text-align: right;
}
.col-buttons div {
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .approve-button {
    font-size: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .reject-button {
    font-size: 60%;
  }
}

.close-button {
  margin-left: 0.5em;
}
@media only screen and (max-width: 768px) {
  .close-button {
    font-size: 60%;
  }
}

.subcomponent-button {
  font-size: 0.875em;
}

.modal {
  display: block;
}

.modal-details-text {
  opacity: 0.8;
}

input {
  width: 40%;
}

.modal-footer {
  background-color: whitesmoke;
}

.modal-lg {
  max-width: 50%;
}

.ReactTable .rt-resizable-header {
  padding: 2px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ReactTable .rt-resizer:before {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 3px;
  height: 18px;
  width: 18px;
  color: transparent;
  content: ".";
  background-size: 18px 18px;
  background-repeat: no-repeat;
  opacity: 0.87;
}

.ReactTable .rt-resizable-header-content:after {
  position: absolute;
  right: 8px;
  top: 3px;
  height: 18px;
  width: 18px;
  z-index: 120;
  color: transparent;
  content: ".";
}

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
  background-image: url(../assets/sort-asc.png);
}

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  background-image: url(../assets/sort-desc.png);
}


.plainselect { 
  margin: 0 4px;
  font-weight: 500;
  cursor: pointer;
  color:grey;
  border: 2px solid rgb(74 72 82);
    color: #ffffff;
    background-color: #2f2655;
    padding: 4px 9px;
    border-radius: 10px;

}

.secndlayer {
  padding: 10px;
  background-color: #f0f2f5; 

}

.box-margin{
  margin-bottom: 8px;
}

.selected-flter{
  border: 2px solid  #ffffff;;
  color: #2f2655;
  background-color:#ffffff;
}

.rt-tr-group:hover{
  /* background: green; */ 
  font-size: 16px;
  font-weight: 600;
}
.rt-tr-group:nth-of-type(even){
  background-color: #f0f2f5;
}
/* .estimationfirstlayer{
  padding: 10px;
  background-color: #f0f2f5; 
}
.estimationsecndlayer{ 
  background-color: #ffffff; 
} */

.setcolorspncheader>div>div>table>thead{
  color:whitesmoke;
  background: rgb(47, 38, 85);
}