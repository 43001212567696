.login-form {
  margin-top: 5%;
}

.company-image {
  color: white;
  width: 45%;
  height: 90%;
  margin-left: 0.5em;
  margin-top: 0.2em;
}
@media only screen and (max-width: 768px) {
  .company-image {
    width: 180%;
    height: 120%;
  }
}
