@charset "UTF-8";
.loading-ellipsis {
  font-size: 1.25em;
  display: inline-block;
}
.loading-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(10, end) 1s infinite;
  animation: ellipsis steps(10, end) 1s infinite;
  content: "\2026\2026";
  /* ascii code for the ellipsis character */
}

@keyframes ellipsis {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  24.9% {
    clip-path: inset(0 83.3% 0 0);
  }
  25% {
    clip-path: inset(0 66.6% 0 0);
  }
  49.9% {
    clip-path: inset(0 49.9% 0 0);
  }
  50% {
    clip-path: inset(0 33.3% 0 0);
  }
  74.9% {
    clip-path: inset(0 16.65% 0 0);
  }
  75% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}
@-webkit-keyframes ellipsis {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  24.9% {
    clip-path: inset(0 83.3% 0 0);
  }
  25% {
    clip-path: inset(0 66.6% 0 0);
  }
  49.9% {
    clip-path: inset(0 49.9% 0 0);
  }
  50% {
    clip-path: inset(0 33.3% 0 0);
  }
  74.9% {
    clip-path: inset(0 16.65% 0 0);
  }
  75% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}
