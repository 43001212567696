.footer {
  background-color: #2f2655;
  height: 18px;
  ;
}

.footer-content {
  text-align: right;
  color: lightgrey;
  font-size: 8px;
}
