@font-face {
  font-family: "Libre Franklin";
  src: url(../assets/Fonts/Libre_Franklin/LibreFranklin-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Open Sans";
  src: url(../assets/Fonts/Open_Sans/OpenSans-Regular.ttf) format("truetype");
}
.sidenav-bar {
  min-height: 100%;
  background-image: linear-gradient(#ced4da, #ced4da73, #ced4da80);
  position: fixed;
  min-width: 10%;
}
.sidenav-bar ul {
  list-style-type: None;
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .sidenav-bar {
    min-width: 22%;
  }
}

.sidenav-button {
  padding: 5%;
  display: block;
  position: relative;
  font-family: "Open Sans";
  font-weight: 600;
  cursor: pointer;
}
.sidenav-button:hover {
  background-color: #6f7a8334;
}
.sidenav-button.selected {
  background-color: #6f7a8334;
}

.menuLabel {
  display: block;
  color: black;
  text-decoration: none;
  white-space: nowrap;
}
.menuLabel:hover {
  color: whitesmoke;
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .menuLabel {
    font-size: 75%;
  }
}
