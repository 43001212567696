@font-face {
  font-family: "Libre Franklin";
  src: url(../assets/Fonts/Libre_Franklin/LibreFranklin-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Open Sans";
  src: url(../assets/Fonts/Open_Sans/OpenSans-Regular.ttf) format("truetype");
}
.header {
  /* background-image: linear-gradient(to left, #c8c8c8, #c4c3cd, #bfbfd2, #b8bbd7, #b0b7dc, #b0b7dc, #b0b7dc, #b0b7dc, #b8bbd7, #bfbfd2, #c4c3cd, #c8c8c8); */
  position: relative;
  min-width: 100%;
  min-height: 7%;
  box-shadow: 0px 4px #2f2655;
}
@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
  }
}

.header-text {
  display: inline-block;
  color: black;
  font-family: "Libre Franklin";
  font-weight: 800;
  text-align: center;
  margin-top: 0.25em;
}
@media only screen and (max-width: 768px) {
  .header-text {
    font-size: 90%;
  }
}

.header-image {
  color: white;
  width: 35%;
  height: 80%;
  margin-left: 2%;
  margin-top: 0.25em;
}
@media only screen and (max-width: 768px) {
  .header-image {
    width: 50%;
  }
}

.header-link {
  margin-top: 0.35em;
  margin-right: 4%;
  color: black;
  text-decoration: none;
}
.header-link:hover {
  color: black;
  background-image: linear-gradient(#c5d2fc, #ECECEC, #fcedc5);
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .header-link {
    font-size: 75%;
  }
}

.header-dropdown {
  background: none;
  border: none;
  margin-right: 1em;
  padding: 0;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .header-dropdown {
    font-size: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .dropdown-menu {
    width: 25%;
  }
}

@media only screen and (max-width: 768px) {
  .dropdown {
    font-size: 75%;
  }
}
