.dashboard {
  position: relative;
  display: block;
}
@media only screen and (max-width: 768px) {
  .dashboard {
    height: 60%;
    width: 60%;
  }
}

.info-text {
  margin-top: 1em;
}
@media only screen and (max-width: 768px) {
  .info-text {
    font-size: 100%;
  }
}

.button-row {
  margin-top: 5em;
}

.pending-button {
  height: 400%;
  width: 85%;
  color: black;
  opacity: 0;
  white-space: normal;
  max-height: 10rem;
  border-radius: 35%;
}
@media only screen and (max-width: 768px) {
  .pending-button {
    height: 150%;
    width: 75%;
  }
}

.pending-button:hover {
  cursor: pointer;
}

.count-bar {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .col-md-4 {
    padding-bottom: 2rem;
  }
}


.margindashbord{
  margin-left:50px;
}