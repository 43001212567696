.adduser-header {
  text-align: center;
}

.adduser-form {
  margin-left: 5vw;
}

.input-field {
  width: 25vw;
}
@media only screen and (max-width: 768px) {
  .input-field {
    width: 50vw;
  }
}

@media only screen and (max-width: 768px) {
  .form-button {
    width: 45%;
  }
}

.select-user-type {
  color: #444;
  background-color: #fff;
  line-height: 5%;
  font-weight: 500;
  border: 1px solid #aaa;
  border-radius: 0.35em;
  padding: 0.1em 0.1em 0.1em 0.1em;
}
.select-user-type:hover {
  border-color: #888;
}
.select-user-type:focus {
  border-color: #aaa;
  color: #222;
  outline: none;
  box-shadow: 0 0 0 1.5px #b8bbd7;
}
.select-user-type option {
  font-weight: normal;
}
