@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.625em;
    opacity: 1;
  }
  40% {
    height: 1.25em;
    width: 0.625em;
    opacity: 1;
  }
  100% {
    height: 1.25em;
    width: 0.625em;
    opacity: 1;
  }
}
@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.625em;
    opacity: 1;
  }
  40% {
    height: 1.25em;
    width: 0.625em;
    opacity: 1;
  }
  100% {
    height: 1.25em;
    width: 0.625em;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.625em;
    opacity: 1;
  }
  40% {
    height: 1.25em;
    width: 0.625em;
    opacity: 1;
  }
  100% {
    height: 1.25em;
    width: 0.625em;
    opacity: 1;
  }
}
.checkmark-circle {
  width: 2.5em;
  height: 2.5em;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.checkmark-circle .background {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background: #2EB150;
}
.checkmark-circle .checkmark {
  border-radius: 5px;
}

.checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.checkmark-circle .checkmark:after {
  opacity: 1;
  height: 1.25em;
  width: 0.625em;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 0.25em solid white;
  border-top: 0.25em solid white;
  border-radius: 2.5px !important;
  content: "";
  left: 0.6em;
  top: 1.3em;
  position: absolute;
}
